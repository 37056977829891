import { ChainId } from '@pancakeswap/sdk'

export const SUPPORT_ONLY_BSC = [ChainId.ZCD, ChainId.ZCD_TESTNET, ChainId.ETHEREUM, ChainId.ETHEREUM] // edit
export const SUPPORT_FARMS = [ChainId.ZCD, ChainId.ZCD_TESTNET, ChainId.ETHEREUM]

export const SUPPORT_ZAP = [ChainId.ZCD, ChainId.ZCD_TESTNET, ChainId.ETHEREUM]
export const SUPPORT_STAKING = [ChainId.BSC_TESTNET]

export const CHART_SUPPORT_CHAIN_IDS = [ChainId.ZCD, ChainId.ZCD_TESTNET, ChainId.ETHEREUM]
export const ACCESS_TOKEN_SUPPORT_CHAIN_IDS = [ChainId.ZCD, ChainId.ZCD_TESTNET, ChainId.ETHEREUM]
export const STABLE_SUPPORT_CHAIN_IDS = [ChainId.ZCD, ChainId.ZCD_TESTNET, ChainId.ETHEREUM]
