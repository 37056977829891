import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import Flex from "../../../components/Box/Flex";
import { LogoIcon, LogoWithTextIcon } from "../../../components/Svg";
import { MenuContext } from "../context";

interface Props {
  href: string;
}

const blink = keyframes`
  0%,  100% { transform: scaleY(1); }
  50% { transform:  scaleY(0.1); }
`;

const StyledLink = styled("a")`
  display: flex;
  height: 60px;
  width: 160px;
  .mobile-icon {
    width: 60px;
    ${({ theme }) => theme.mediaQueries.lg} {
      display: none;
    }
  }
  .desktop-icon {
    display: none;
    ${({ theme }) => theme.mediaQueries.lg} {
      display: block;
    }
  }
  .eye {
    animation-delay: 20ms;
  }
  &:hover {
    .eye {
      transform-origin: center 60%;
      animation-name: ${blink};
      animation-duration: 350ms;
      animation-iteration-count: 1;
    }
  }
`;

const Logo: React.FC<React.PropsWithChildren<Props>> = ({ href }) => {
  const { linkComponent } = useContext(MenuContext);
  const isAbsoluteUrl = href.startsWith("http");
  const innerLogo = (
    <>
      {/* <LogoIcon className="mobile-icon" /> */}
      <img className="mobile-icon" height={60} src="/images/logo-icon.jpeg" alt="" />
      <img className="desktop-icon" height={60} src="/images/logo-text.jpeg" alt="" />
      {/* <LogoWithTextIcon className="desktop-icon" /> */}
    </>
  );

  return (
    <Flex alignItems="center">
      {isAbsoluteUrl ? (
        <StyledLink as="a" href={href} aria-label="Pancake home page">
          <Flex>
            {innerLogo}
            <div
              style={{
                height: 60,
                display: "flex",
                alignItems: "center",
                fontStyle: "italic",
                fontWeight: "900",
                fontSize: "22px",
                color: "var(--colors-primary)",
              }}
            >
              ZWAP
            </div>
          </Flex>
        </StyledLink>
      ) : (
        <StyledLink href={href} as={linkComponent} aria-label="Pancake home page">
          {innerLogo}
          <div
            style={{
              height: 60,
              display: "flex",
              alignItems: "center",
              fontStyle: "italic",
              fontWeight: "900",
              fontSize: "22px",
              color: "var(--colors-primary)",
            }}
          >
            ZWAP
          </div>
          <div
            style={{ height: 40, width: 1, background: "var(--colors-primary)", marginTop: 10, marginLeft: 25 }}
          ></div>
        </StyledLink>
      )}
    </Flex>
  );
};

export default React.memo(Logo);
